import React, { useState } from "react";
import clsx from "clsx";

export default function InfoIcon(props: any) {
  const [mouseEventClasses, setMouseEventClasses] = useState("hidden");

  const onMouseEnterHandler = () => {
    setMouseEventClasses("");
  };

  const onMouseLeaveHandler = () => {
    setMouseEventClasses("hidden");
  };

  const visibility = props.infoText ? "" : "hidden";

  return (
    <div
      className={clsx(
        "inline-block align-middle ml-2 relative cursor-help",
        visibility
      )}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17.4075C12.3841 17.4075 12.6955 17.1059 12.6955 16.7339V13.1414C12.6955 12.7694 12.3841 12.4678 12 12.4678C11.6159 12.4678 11.3045 12.7694 11.3045 13.1414V16.7339C11.3045 17.1059 11.6159 17.4075 12 17.4075Z"
          fill="#030D45"
        />
        <path
          d="M12.9273 10.447C12.9273 9.95097 12.5121 9.54886 12 9.54886C11.4879 9.54886 11.0727 9.95097 11.0727 10.447C11.0727 10.943 11.4879 11.3451 12 11.3451C12.5121 11.3451 12.9273 10.943 12.9273 10.447Z"
          fill="#030D45"
        />
        <path
          fill="#030D45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.93806 4.05924C10.9341 2.64692 13.0659 2.64692 14.0619 4.05924C15.2971 5.81057 17.0453 8.35811 18.2149 10.3557C19.4427 12.4526 20.8406 15.3228 21.7707 17.3011C22.5029 18.8582 21.3983 20.6231 19.6389 20.7242C17.4713 20.8488 14.3569 21 12 21C9.64305 21 6.52872 20.8488 4.36111 20.7242C2.60171 20.6231 1.49715 18.8582 2.22926 17.3011C3.1594 15.3228 4.55728 12.4526 5.78508 10.3557C6.9547 8.35811 8.70292 5.81057 9.93806 4.05924ZM12.9134 4.81913C12.4696 4.18988 11.5304 4.18988 11.0866 4.81913C9.85303 6.56821 8.13356 9.07567 6.99496 11.0202C5.79998 13.0611 4.42375 15.8842 3.49509 17.8594C3.1694 18.5521 3.655 19.3341 4.44348 19.3794C6.60873 19.5038 9.68756 19.6528 12 19.6528C14.3124 19.6528 17.3913 19.5038 19.5565 19.3794C20.345 19.3341 20.8306 18.5521 20.5049 17.8594C19.5762 15.8842 18.2 13.0611 17.005 11.0202C15.8664 9.07567 14.147 6.56821 12.9134 4.81913Z"
        />
      </svg>

      <div
        className={clsx(
          "absolute bg-white rounded-lg shadow mx-auto sm:overflow-hidden p-3 w-[500px] left-6 top-0 text-sm font-normal",
          mouseEventClasses
        )}
        style={{ zIndex: `9999999` }}
      >
        <div dangerouslySetInnerHTML={{ __html: props.infoText }} />
      </div>
    </div>
  );
}
