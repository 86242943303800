/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext } from "react";
import clsx from "clsx";
import { isArray, isNumber } from "lodash";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
} from "../../../types/UIElements";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface TextareaFieldProps {
  name: string;
  placeholder?: string;
  height?: number;
  isMulti?: boolean;
  additionalClasses?: string;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
}
export default function TextareaField(props: TextareaFieldProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const {
    name,
    placeholder,
    isMulti,
    height,
    additionalClasses,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
  } = props;

  let fieldValues = [""];

  if (isNumber(referenceNumber)) {
    fieldValues = submissionState.publication[referenceNumber][name]
      ? isArray(submissionState.publication[referenceNumber][name]) // check if array
        ? submissionState.publication[referenceNumber][name] // use array value
        : [submissionState.publication[referenceNumber][name]] // or construct array with value
      : [""]; // when value is falsy provide default value
  } else if (isNumber(acknowledgementNumber)) {
    // Fix bug https://docs.google.com/document/d/1_xMRNxVDEVbA7EUAY6sUzfYOUmo9NtkO1sqmGLISfDo/edit#bookmark=id.10tri2qzwduv 
    // submissionState.acknowledgement[acknowledgementNumber][name] is undefined
    // Refactor to improve readability as per recommendation https://docs.google.com/document/d/1_xMRNxVDEVbA7EUAY6sUzfYOUmo9NtkO1sqmGLISfDo/edit#bookmark=id.r08i5pkg9blt. 
    // Note it will not deal with values as array. We do not have such legacy data.
    fieldValues = [
      submissionState.acknowledgement[acknowledgementNumber]?.publication?.[
        name
      ],
    ] || [""];
  } else if (participantsPropertyList.includes(name)) {
    // Unused code?!, We do not use TextareaFiekd at Participant form e.g. Step 3
    fieldValues = submissionState.participant[name]
      ? isArray(submissionState.participant[name])
        ? submissionState.participant[name]
        : [submissionState.participant[name]]
      : [""];
  } else if (instrumentPropertyList.includes(name)) {
    // Used at Step 3, notes form
    fieldValues = submissionState.instrument[name]
      ? isArray(submissionState.instrument[name])
        ? submissionState.instrument[name]
        : [submissionState.instrument[name]]
      : [""];
  } else if (settingsPropertyList.includes(name)) {
    // Used at Step 7, comments form
    fieldValues = submissionState.settings[name]
      ? isArray(submissionState.settings[name])
        ? submissionState.settings[name]
        : [submissionState.settings[name]]
      : [""];
  } else {
    fieldValues = submissionState[name] ? submissionState[name] : [""];
  }

  // Detect which handler to use
  let onChangeHandler = "inputChangeHandler";
  if (isNumber(referenceNumber)) {
    onChangeHandler = "publicationChangeHandler";
  } else if (isNumber(acknowledgementNumber)) {
    onChangeHandler = "acknowledgementChangeHandler";
  } else if (participantsPropertyList.includes(name)) {
    onChangeHandler = "participantChangeHandler";
  } else if (instrumentPropertyList.includes(name)) {
    onChangeHandler = "instrumentChangeHandler";
  } else if (settingsPropertyList.includes(name)) {
    onChangeHandler = "settingsChangeHandler";
  } else {
    //
  }

  return (
    // Why do we itterate fieldValues? Do we expect to see array with more than one element?
    <>
      {fieldValues.map((v: any, i: any) => {
        const key = name;

        const additionalMarginClass = i > 0 ? "mt-2" : "";

        return (
          <textarea
            name={name}
            id={key}
            key={key}
            placeholder={placeholder || ""}
            className={clsx(
              "w-full css-1s2u09g-control p-2",
              additionalClasses,
              additionalMarginClass
            )}
            style={{ height: `${height || 40}px` }}
            onChange={(e) => {
              // Cancel current saveTimer when new changes are coming in before timeout passes
              clearTimeout(saveTimer);

              submissionDispatch({
                type: onChangeHandler,
                value: e.target.value,
                fieldName: name,
                referenceNumber,
                acknowledgementNumber,
              });

              // Trigger save after X milliseconds to avoid too many save requests
              // timer is canceled on new change event
              saveTimer = setTimeout(() => {
                triggerSaveMessageHandler();
              }, 500);
            }}
            value={v}
          >
            {v}
          </textarea>
        );
      })}
    </>
  );
}
