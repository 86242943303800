const instrumentPropertyList = [
  "creator",
  "materialType",
  "researchArea",
  "typeOfFile",
  "dataType",
  "linguisticFeature",
  "language",
  "sourceOfFunding",
  "title",
  "reliability",
  "notes",
];

const participantsPropertyList = [
  "type",
  "firstLanguage",
  "languageBeingLearned",
  "ageOfLearner",
  "genderOfLearners",
  "proficiencyOfLearners",
  "typeOfInstruction",
  "amountOfInstruction",
  "lengthOfResidence",
  "ageOfLearner",
  "amountOfPriorInstruction",
  "timeSpent",
  "domainOfUse",
  "ageOfArrival",
];

const settingsPropertyList = [
  "rights",
  "feedback",
  "notifyDownloads",
  "email",
  "comments",
  "licenceAgreement",
];

export {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
};
