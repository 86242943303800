/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext } from "react";
import clsx from "clsx";
import { isArray, isNumber } from "lodash";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
} from "../../../types/UIElements";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface InputFieldProps {
  name: string;
  placeholder?: string[];
  preText?: string[];
  additionalClasses?: string;
  isMulti?: boolean;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
  disabled?: boolean;
  materialSettings?: any;
  doiField?: boolean;
}

export default function InputField(props: InputFieldProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  let { disabled } = props;

  const {
    name,
    placeholder,
    preText,
    additionalClasses,
    isMulti,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
    materialSettings,
    doiField,
  } = props;

  // eslint-disable-next-line prettier/prettier
  const { publication, acknowledgement, participant, instrument, settings } = submissionState;

  let fieldValues = [""];

  if (isNumber(referenceNumber)) {
    fieldValues = publication[referenceNumber][name]
      ? isArray(publication[referenceNumber][name]) &&
        publication[referenceNumber][name].length > 0
        ? publication[referenceNumber][name]
        : [publication[referenceNumber][name]]
      : [""];
  } else if (isNumber(acknowledgementNumber)) {
    fieldValues =
      acknowledgement[acknowledgementNumber].publication &&
      acknowledgement[acknowledgementNumber].publication[name]
        ? isArray(acknowledgement[acknowledgementNumber].publication[name]) &&
          acknowledgement[acknowledgementNumber].publication[name].length > 0
          ? acknowledgement[acknowledgementNumber].publication[name]
          : [acknowledgement[acknowledgementNumber].publication[name]]
        : [""];
  } else if (participantsPropertyList.includes(name)) {
    fieldValues = participant[name]
      ? isArray(participant[name]) && participant[name].length > 0
        ? participant[name]
        : [participant[name]]
      : [""];
  } else if (instrumentPropertyList.includes(name)) {
    fieldValues = instrument[name]
      ? isArray(instrument[name]) && instrument[name].length > 0
        ? instrument[name]
        : [instrument[name]]
      : [""];
  } else if (settingsPropertyList.includes(name)) {
    fieldValues = settings[name]
      ? isArray(settings[name])
        ? settings[name]
        : [settings[name]]
      : [""];
  } else {
    fieldValues = submissionState[name] ? [submissionState[name]] : [""];
  }

  // Detect which handler to use
  let onChangeHandler = "inputChangeHandler";
  if (isNumber(referenceNumber)) {
    onChangeHandler = "publicationChangeHandler";
  } else if (isNumber(acknowledgementNumber)) {
    onChangeHandler = "acknowledgementChangeHandler";
  } else if (participantsPropertyList.includes(name)) {
    onChangeHandler = "participantChangeHandler";
  } else if (instrumentPropertyList.includes(name)) {
    onChangeHandler = "instrumentChangeHandler";
  } else if (settingsPropertyList.includes(name)) {
    onChangeHandler = "settingsChangeHandler";
  } else {
    //
  }

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: doiField || false,
  });

  const noteText = InputFieldsHelper.getInputDisabledNote({
    materialSettings,
    doiField: doiField || false,
  });

  return (
    <>
      {fieldValues.map((v: any, i: any) => {
        const key = isMulti ? `${name}[${i}]` : name;

        const showPreText = preText
          ? isArray(preText)
            ? preText[i]
            : preText || ""
          : "";

        const additionalMarginClass = i > 0 ? "mt-2" : "";

        return (
          <div key={key} className={additionalMarginClass}>
            {showPreText}
            {noteText}
            <input
              type="text"
              id={key}
              key={key}
              placeholder={
                isArray(placeholder) ? placeholder[i] : placeholder || ""
              }
              disabled={!!disabled}
              className={clsx(
                "w-full css-1s2u09g-control css-13cymwt-control px-2",
                additionalClasses
              )}
              onChange={(e) => {
                // Cancel current saveTimer when new changes are coming in before timeout passes
                clearTimeout(saveTimer);

                // Update state
                submissionDispatch({
                  type: onChangeHandler,
                  value: e.target.value,
                  fieldName: isMulti ? `${name}[]` : name,
                  i,
                  isMulti,
                  referenceNumber,
                  acknowledgementNumber,
                });

                // Trigger save after X milliseconds to avoid too many save requests
                // timer is canceled on new change event
                saveTimer = setTimeout(() => {
                  triggerSaveMessageHandler();
                }, 500);
              }}
              value={v}
            />
          </div>
        );
      })}

      {/* {isMulti ?
        <button className="py-2 inline-block" onClick={addEmptyInputValue}>+ add more</button>
        : ''} */}
    </>
  );
}
