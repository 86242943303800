import React, { useContext } from "react";
import clsx from "clsx";
import { uniqueId } from "lodash";
import { Link } from "gatsby";
import H2 from "../htmlElements/h2";

import submitSteps from "../../data/submitSteps";
import { SubmissionState } from "../../context/SubmissionContext";
import H4 from "../htmlElements/h4";

interface StepProps {
  i: string;
  activeStep: string;
  materialID: string;
  options: {
    title: string;
  };
}

function Step(props: StepProps) {
  const { i, activeStep, options, materialID } = props;

  const step = parseInt(i, 10) + 1;

  const isActiveStep = step === parseInt(activeStep, 10);

  const inactiveClasses = "border-b border-b-gray-2";

  const activeClasses = "font-bold";

  return (
    <div
      className={clsx(
        "py-2 mb-0  relative w-full",
        isActiveStep ? activeClasses : inactiveClasses
      )}
    >
      <div className="grid grid-cols-12 gap-0">
        <div className="col-span-2 md:col-span-2">
          {step < 8 ? (
            <Link to={`/submit/${materialID}/${step}`}>Step {step}:</Link>
          ) : (
            `Step ${step}:`
          )}
        </div>
        <div className="col-span-10 md:col-span-10">{options.title}</div>
      </div>
    </div>
  );
}

interface LeftColumnContentProps {
  activeStep: string;
  materialID: string;
}

function LeftColumnContent(props: LeftColumnContentProps) {
  const submissionState = useContext(SubmissionState) || {
    objectMetadata: { status: "draft", createdAt: "", lastModifiedAt: "" },
  };

  const { activeStep, materialID } = props;

  const { objectMetadata } = submissionState;

  const status =
    objectMetadata && objectMetadata.status ? objectMetadata.status : "draft";

  const createdAt =
    objectMetadata && objectMetadata.createdAt
      ? new Date(objectMetadata.createdAt).toUTCString()
      : "";

  const lastModifiedAt =
    objectMetadata && objectMetadata.lastModifiedAt
      ? new Date(objectMetadata.lastModifiedAt).toUTCString()
      : "";

  const steps = submitSteps.map((stepOption, i) => (
    <Step
      i={i}
      options={stepOption}
      activeStep={activeStep}
      key={uniqueId()}
      materialID={materialID}
    />
  ));

  const icon = status === "published" ? "✅ " : "⌛ ";

  const noteMessage =
    status === "published"
      ? ""
      : "⚠️ This submission is not completed yet and others will not be able to see it. You need to complete the submission before it will be available and searchable.";

  return (
    <div className="sticky top-5 md:block">
      <H2 innerContent="Submission progress" additionalClasses="md:pb-5" />

      <div className="relative hidden md:block text-sm">{steps}</div>

      <div className="pt-5 mt-0 border-t border-t-gray-2">
        <div className="bg-white rounded-lg shadow mx-auto sm:overflow-hidden sticky top-5 pt-3 pb-5 px-5">
          <H4 innerContent="Submission information" additionalClasses="pb-3" />
          <div className="mb-2">
            <div className="mr-2 inline">Current status:</div>
            <div className="px-2 py-1 bg-orange-xx inline rounded text-sm">
              {icon} {status}
            </div>
          </div>
          {createdAt ? (
            <div className="mb-2">
              <div className="mr-2 inline">Created at:</div>
              <div className="px-2 py-1 inline rounded text-sm">
                {createdAt}
              </div>
            </div>
          ) : (
            ""
          )}
          {lastModifiedAt ? (
            <div className="mb-2">
              <div className="mr-2 inline">Modified at:</div>
              <div className="px-2 py-1 inline rounded text-sm">
                {lastModifiedAt}
              </div>
            </div>
          ) : (
            ""
          )}
          <div>{noteMessage}</div>
        </div>
      </div>
    </div>
  );
}

export default LeftColumnContent;
