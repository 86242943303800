const submitSteps = [
  {
    title: "Upload",
  },
  {
    title: "Material details",
  },
  {
    title: "Participants",
  },
  {
    title: "Linked publications",
  },
  {
    title: "Reference for your IRIS material",
  },
  {
    title: "Acknowledgements",
  },
  {
    title: "Licence, Communication and Terms & Conditions",
  },
  {
    title: "Submission",
  },
  {
    title: "Confirmation",
  },
];

export default submitSteps;
