/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import { isArray, isNumber } from "lodash";
import React from "react";
import {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
} from "../types/UIElements";

interface CheckInputDisabledStatusProps {
  materialSettings: object;
  doiField: boolean;
  text?: string;
  adminText?: string;
}

interface CommonDisabledHelperResponse {
  disabled: boolean;
  noteText: any;
  text?: string;
  adminText?: string;
}

const defaultText = `This material has been published and the DOI has been created on DataCite.<br />This field cannot be changed by you. If you wish to ask for any changes, please contact IRIS admin at <a href="mailto:iris@iris-database.org">iris@iris-database.org</a> who will consider your request.`;
const defaultAdminText = `This material has been published and the DOI has been created on DataCite.<br />Only you the ADMIN can make changes to this field.`;

const makeNoteBox = (text: string) => {
  return (
    <div className="flex w-full   p-2 mb-2 border-2 border-orange rounded">
      <div className="flex-initial">
        <span className="block" style={{ fontSize: `3em`, paddingTop: `7px` }}>
          ⚠️
        </span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default {
  commonDisabledHelper(
    props: CheckInputDisabledStatusProps
  ): CommonDisabledHelperResponse {
    const response = {
      disabled: false,
      noteText: "",
    };

    const { materialSettings, doiField, text, adminText } = props;

    if (materialSettings !== undefined && doiField) {
      if (materialSettings.status === "published") {
        response.disabled = true;

        response.noteText = makeNoteBox(text || defaultText);

        if (materialSettings.isAdminUser) {
          response.disabled = false;

          response.noteText = makeNoteBox(adminText || defaultAdminText);
        }
      }
    }

    return response;
  },

  checkInputDisabledStatus(props: CheckInputDisabledStatusProps): boolean {
    const check = this.commonDisabledHelper(props);
    return check.disabled;
  },
  getInputDisabledNote(props: CheckInputDisabledStatusProps): any {
    const check = this.commonDisabledHelper(props);
    return check.noteText;
  },
};

interface GetSelectedValuesProps {
  name: string;
  submissionState: any;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  customFieldValues?: any;
}

/**
 * This function will return the selected values for a specifiec field.
 * @param name The name of the field
 * @param referenceNumber The number of the reference, always starts with a zero (0) - These are publications
 * @param acknowledgementNumber The number of the acknowledgement, always starts with a zero (0) - These are acknowledgements
 * @param submissionState The current submissionState
 * @param customFieldValues customFieldValues
 * @returns
 */
export const getSelectedValues = (props: GetSelectedValuesProps) => {
  const {
    name,
    referenceNumber,
    acknowledgementNumber,
    submissionState,
    customFieldValues,
  } = props;

  const { publication, acknowledgement, participant, instrument, settings } =
    submissionState;

  let fieldValues = [""];

  if (customFieldValues) {
    fieldValues = customFieldValues;
  } else if (isNumber(referenceNumber)) {
    fieldValues = publication[referenceNumber][name]
      ? isArray(publication[referenceNumber][name])
        ? publication[referenceNumber][name]
        : [publication[referenceNumber][name]]
      : [];
  } else if (isNumber(acknowledgementNumber)) {
    if (name === "type") {
      fieldValues = acknowledgement[acknowledgementNumber][name]
        ? isArray(acknowledgement[acknowledgementNumber][name])
          ? acknowledgement[acknowledgementNumber][name]
          : [acknowledgement[acknowledgementNumber][name]]
        : [];
    } else {
      fieldValues =
        acknowledgement[acknowledgementNumber].publication &&
        acknowledgement[acknowledgementNumber].publication[name]
          ? isArray(acknowledgement[acknowledgementNumber].publication[name])
            ? acknowledgement[acknowledgementNumber].publication[name]
            : [acknowledgement[acknowledgementNumber].publication[name]]
          : [];
    }
  } else if (participantsPropertyList.includes(name)) {
    fieldValues = participant[name]
      ? isArray(participant[name])
        ? participant[name]
        : [participant[name]]
      : [];
  } else if (instrumentPropertyList.includes(name)) {
    fieldValues = instrument[name]
      ? isArray(instrument[name])
        ? instrument[name]
        : [instrument[name]]
      : [];
  } else if (settingsPropertyList.includes(name)) {
    fieldValues = settings[name]
      ? isArray(settings[name])
        ? settings[name]
        : [settings[name]]
      : [];
  } else {
    fieldValues = submissionState[name] ? submissionState[name] : [];
  }

  return fieldValues;
};
