import { graphql, useStaticQuery } from "gatsby";
import React from "react";

interface StepProps {
  submissionStatus: any;
  missingRequiredFields: any;
}

export default function Step8(props: StepProps) {
  const { missingRequiredFields, submissionStatus } = props;

  const missingFields = missingRequiredFields.map((item: any) => {
    return <li key={item + item.message}>- {item.message}</li>;
  });

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 8 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>
      {submissionStatus === "submitting" ? (
        <div className="text-lg pb-5">
          Please wait while we are submitting the details.
        </div>
      ) : (
        ""
      )}
      {submissionStatus === "submitted" ? (
        <div className="text-lg pb-5">
          Done! The material has been created succesfully.
        </div>
      ) : (
        ""
      )}
      {submissionStatus === "error" ? (
        <div className="text-lg pb-5">Oops, something went wrong.</div>
      ) : (
        ""
      )}
      {submissionStatus === "missingRequiredFields" &&
      missingFields.length > 0 ? (
        <div style={{ marginBottom: `15px` }}>
          Sorry, some required fields are missing. Please go back and fill out
          these missing required fields:
          <br />
          <ul>{missingFields}</ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
