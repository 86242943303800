import React from "react";
import InfoIcon from "../htmlElements/infoIcon";
import RequiredIcon from "../htmlElements/requiredIcon";
import clsx from "clsx";

interface LayoutProps {
  title: string;
  infoBoxContent?: string;
  children?: any;
  hideBorder?: boolean;
  hidePadding?: boolean;
  hideMargin?: boolean;
  titlePadding?: string;
  required?: boolean;
  customClasses?: string;
}

export default function InputWrapper(props: LayoutProps) {
  const borderClasses = props.hideBorder ? "" : "border-b border-b-gray-2";
  const paddingClasses = props.hidePadding ? "" : "pb-3";
  const marginClasses = props.hideMargin ? "" : "mb-3";

  const titlePaddingClasses = props.titlePadding ? props.titlePadding : "pb-2";

  const required = props.required ? <RequiredIcon /> : "";

  return (
    <>
      <div
        className={clsx(
          paddingClasses,
          marginClasses,
          borderClasses,
          props.customClasses
        )}
      >
        <div className={clsx("font-bold text-base", titlePaddingClasses)}>
          {props.title}
          {required}
          <InfoIcon infoText={props.infoBoxContent} />
        </div>

        {props.children}
      </div>
    </>
  );
}
