/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from "react";
import { Link, PageProps } from "gatsby";
import clsx from "clsx";
import { validateToken, userRightsDecoded } from "../../../lib/functions";
import LoadingIcon from "../../../components/loadingIcon";
import submitSteps from "../../../data/submitSteps";
import Layout from "../../../components/layout";
import LeftColumnContent from "../../../components/submitElements/leftColumnContent";
import RightColumnContent from "../../../components/submitElements/rightColumnContent";

import Step0 from "../../../components/submitElements/steps/step0";
import Step1 from "../../../components/submitElements/steps/step1";
import Step2 from "../../../components/submitElements/steps/step2";
import Step3 from "../../../components/submitElements/steps/step3";
import Step4 from "../../../components/submitElements/steps/step4";
import Step5 from "../../../components/submitElements/steps/step5";
import Step6 from "../../../components/submitElements/steps/step6";
import Step7 from "../../../components/submitElements/steps/step7";
import Step8 from "../../../components/submitElements/steps/step8";
import Step9 from "../../../components/submitElements/steps/step9";

import { AuthenticationState } from "../../../context/AuthenticationContext";
import { SubmissionState, SubmissionDispatch } from "../../../context/SubmissionContext"; // eslint-disable-line prettier/prettier
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import { submitMaterialMetadata } from "../../../components/submitElements/submitMaterialMetadata";
import metadataReverseFormatter from "../../../helpers/MetadataReverseFormatter";
import getDetailsData from "../../../helpers/getDetailsMetadata";

interface StepDisplayProps {
  step: number;
  submissionStatus: any;
  missingRequiredFields?: any;
  triggerSaveMessageHandler: any;
  materialID: string;
  materialSettings: any;
}

function StepDisplay(props: StepDisplayProps) {
  const {
    step,
    triggerSaveMessageHandler,
    submissionStatus,
    missingRequiredFields,
    materialID,
    materialSettings,
  } = props;

  switch (parseInt(step, 10)) {
    case 0:
      return <Step0 />;
    case 1:
      return (
        <Step1
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
        />
      );
    case 2:
      return (
        <Step2
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
        />
      );
    case 3:
      return <Step3 triggerSaveMessageHandler={triggerSaveMessageHandler} />;
    case 4:
      return (
        <Step4
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
        />
      );
    case 5:
      return <Step5 triggerSaveMessageHandler={triggerSaveMessageHandler} />;
    case 6:
      return <Step6 triggerSaveMessageHandler={triggerSaveMessageHandler} />;
    case 7:
      return <Step7 triggerSaveMessageHandler={triggerSaveMessageHandler} />;
    case 8:
      return (
        <Step8
          submissionStatus={submissionStatus}
          missingRequiredFields={missingRequiredFields}
        />
      );
    case 9:
      return <Step9 materialID={materialID} />;
    default:
      break;
  }
}

interface SubmitPageProps extends PageProps {
  materialID: string;
  step: number;
}

export default function SubmitPage(props: SubmitPageProps) {
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const dictionaryDataState = useContext(DictionaryDataState) || "";

  const { token, isAuthenticated } = authenticationState;
  const { materialID, step } = props;

  const [triggerSaveMessage, setTriggerSaveMessage] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("fillingForm");
  const [missingRequiredFields, setMissingRequiredFields] = useState([]);
  const [message, setMessage] = useState("");
  const [materialSettings, setMaterialSettings] = useState({});
  const [materialMetadataLoaded, setMaterialMetadataLoaded] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState(false);

  // For token error handling
  const [stepOverlayClasses, setStepOverlayClasses] =
    useState<string>("hidden");
  const [stepOverlayMessage, setStepOverlayMessage] = useState<any>("");

  /**
   * Check user token each step to make sure he has a valid token
   */
  useEffect(() => {
    (async () => {
      if (token && isAuthenticated) {
        await validateToken(token);
      }
    })();
  }, [step]);

  /**
   * Load in all material metadata
   *
   * ONLY WHEN materialID is available
   */
  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        if (materialID && Object.keys(dictionaryDataState).length) {
          //

          const details = await getDetailsData({
            materialID,
            isAuthenticated,
            token,
          });

          const userRightsAndSettings = userRightsDecoded(
            materialID,
            details.userRights
          );

          if (
            userRightsAndSettings.isOwner ||
            userRightsAndSettings.isAdminUser
          ) {
            setMaterialSettings(userRightsAndSettings);

            try {
              //
              // Here we are running the material metadata through the reverse formatter
              // before saving it to the submission state

              const newMetadata = details.materialMetadata
                ? metadataReverseFormatter({
                    submissionState,
                    dictionaryData: dictionaryDataState,
                    metadata: { data: details.materialMetadata },
                  })
                : {};

              submissionDispatch({
                type: "INSERT_METADATA",
                objectId: materialID,
                objectMetadata: details.objectMetadata,
                materialMetadata: newMetadata,
                filesMetadata: details.filesMetadata,
                doi: details.doi,
              });

              setMaterialMetadataLoaded(true);
              setFilesLoaded(true);

              //
            } catch (error) {
              console.log("🚀 ~ error", error);
              setMessage(
                `Sorry, an error occurred. Please contact the admin team. (error code "step${step}#2")`
              );
            }
          }
        }
      }, 600);
    })();
  }, [materialID, dictionaryDataState]);

  /**
   * When switching from step 4 to step 5, we need to load in the details again to get the DOI Reference.
   * Store the DOI information in the submissionState so we can display the information on step 5.
   */
  useEffect(() => {
    (async () => {
      if (parseInt(step) === 5) {
        if (materialID && Object.keys(dictionaryDataState).length) {
          // reload details
          const details = await getDetailsData({
            materialID,
            isAuthenticated,
            token,
          });

          submissionDispatch({
            type: "INSERT_METADATA",
            doi: details.doi,
          });
        }
      }
    })();
  }, [step]);

  const triggerSaveMessageHandler = async () => {
    // trigger save message
    // setTriggerSaveMessage(true);

    // Submit material
    const response = await submitMaterialMetadata({
      submissionState,
      dictionaryDataState,
      token: authenticationState.token,
    });

    if (response?.status !== 200) {
      // The token might be expired so the user has to login again otherwise we can't save the data
      setStepOverlayClasses(""); // removes the `hidden` class
      setStepOverlayMessage(
        <>
          Sorry, there seems to be an error with your session, could you please{" "}
          <Link to="/login">login</Link> again. If the problem does not go away
          please contact the IRIS admin team.
        </>
      );
    }

    // reset the trigger for next usage
    // setTimeout(() => {
    // setTriggerSaveMessage(false);
    // }, 300);
  };

  // Set span
  const RightColumnContentSpan = step < 9 ? 8 : 12;

  return (
    <Layout>
      {token && isAuthenticated ? (
        message ||
        (materialMetadataLoaded && filesLoaded ? (
          <div className="grid grid-cols-12 gap-4">
            {step < 9 ? (
              <div className="col-span-12 md:col-span-4">
                <LeftColumnContent activeStep={step} materialID={materialID} />
              </div>
            ) : (
              ""
            )}
            <div
              className={`col-span-12 md:col-span-${RightColumnContentSpan}`}
            >
              <RightColumnContent
                step={step}
                materialID={materialID}
                title={
                  submitSteps[step - 1]
                    ? submitSteps[step - 1].title
                    : "IRIS Instrument Submission"
                }
                triggerSaveMessage={triggerSaveMessage}
                submissionStatus={submissionStatus}
                setSubmissionStatus={setSubmissionStatus}
                missingRequiredFields={missingRequiredFields}
                setMissingRequiredFields={setMissingRequiredFields}
              >
                <StepDisplay
                  step={step}
                  submissionStatus={submissionStatus}
                  missingRequiredFields={missingRequiredFields}
                  triggerSaveMessageHandler={triggerSaveMessageHandler}
                  materialID={materialID}
                  materialSettings={materialSettings}
                />
              </RightColumnContent>
            </div>

            <div className={clsx("stepOverlay", stepOverlayClasses)}>
              <div className="innerContent">{stepOverlayMessage}</div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <LoadingIcon />
            <br />
            Loading the material details, please wait.
          </div>
        ))
      ) : (
        <h2 className="text-2xl text-center pt-10 pb-10">
          Please <Link to="/login">login</Link> before submitting.
        </h2>
      )}
    </Layout>
  );
}
