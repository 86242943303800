/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import InputField from "../fields/inputField";
import TextareaField from "../fields/textareaField";
import SelectField from "../fields/selectField";
import TypeOfMaterialPrimarySelectField from "../fields/typeOfMaterialPrimarySelectField";
import HierarchicalSelectField from "../fields/hierarchicalSelectField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";

import NewAuthorForm from "../fields/newAuthorForm";
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import { SubmissionState } from "../../../context/SubmissionContext";
import LoadingIcon from "../../loadingIcon";

import { fileTypesValues } from "../../../data/fileTypeValues";
import NewOtherForm from "../fields/newOtherForm";

// IMPORTANT, we only show dd items that have NO materialId or where the materialId  is equal to our current one!
const ddFilterFunction = (inputArr: any, objectId: string) => {
  if (inputArr && inputArr.length > 0) {
    return inputArr.filter(
      (item: any) =>
        item.materialId === undefined ||
        item.materialId === objectId ||
        item.materialId.includes(objectId) ||
        item.status === "APPROVED"
    );
  }
  return "";
};

interface StepProps {
  triggerSaveMessageHandler: any;
  materialSettings: any;
}

export default function Step2(props: StepProps) {
  const dictionaryDataState: any = useContext(DictionaryDataState) || [];
  const submissionState: any = useContext(SubmissionState) || "";

  const { triggerSaveMessageHandler, materialSettings } = props;

  const { objectId } = submissionState;

  const [authors, setAuthors] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [researchAreas, setResearchAreas] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);
  const [linguisticFeatures, setLinguisticFeatures] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [funders, setFunders] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const setDictionaryData = (updateLoaded = false) => {
    if (!isEmpty(dictionaryDataState)) {
      const {
        authors: ddAuthors,
        typeOfInstruments: ddTypeOfInstruments,
        dataTypes: ddDataTypes,
        linguisticTargets: ddLinguisticTargets,
        languages: ddLanguages,
        funders: ddFunders,
        researchAreas: ddResearchAreas,
      } = dictionaryDataState;

      let { fileTypes: ddFileTypes } = dictionaryDataState;

      if (ddFileTypes.length > 0) {
        ddFileTypes = ddFileTypes.map((item: any) => {
          // Get new value by looking if label in in new label
          const label = fileTypesValues
            .filter((value) => value.from === item.label)
            .map((i) => i.to);

          return { ...item, label: label[0] };
        });
      }

      setAuthors(ddAuthors);
      setMaterialTypes(ddFilterFunction(ddTypeOfInstruments, objectId));
      setResearchAreas(ddFilterFunction(ddResearchAreas, objectId));
      setDataTypes(ddFilterFunction(ddDataTypes, objectId));
      setLinguisticFeatures(ddFilterFunction(ddLinguisticTargets, objectId));
      setLanguages(ddFilterFunction(ddLanguages, objectId));
      setFunders(ddFilterFunction(ddFunders, objectId));
      setFileTypes(ddFilterFunction(ddFileTypes, objectId));

      // Set loaded to `true` so that the content is shown to the user
      if (updateLoaded) {
        setLoaded(true);
      }
    }
  };

  /**
   * We need to set the new values from the dictionaryDataState once they are updated - they
   * can be updated from for example `newOtherForm`
   */
  useEffect(() => {
    setDictionaryData(true);
  }, [dictionaryDataState]);

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 2 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
      materialTitleQuery: allMarkdownRemark(
        filter: {
          frontmatter: { title: { glob: "Step 2 material title text" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;
  const materialTitleText = query.materialTitleQuery.edges[0].node.html;

  return loaded ? (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>

      <InputWrapper
        title="Author (last name, first name or initial)"
        infoBoxContent={infoBoxesContent.authorInfo}
        required
      >
        <SelectField
          name="creator"
          options={authors}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          required
          zIndex={500}
          materialSettings={materialSettings}
          doiField
        />

        <NewAuthorForm
          authors={authors}
          setAuthors={setAuthors}
          fieldName="creator"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Type of Material"
        infoBoxContent={infoBoxesContent.materialTypeInfo}
        required
      >
        <HierarchicalSelectField
          options={materialTypes}
          inputName="materialType"
          irisMaterialSection="instrument"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />

        <NewOtherForm
          title="type of material"
          inputName="materialTypeNew"
          fieldName="materialType"
          dictionaryStateName="typeOfInstruments"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          showParent
          options={materialTypes}
        />
      </InputWrapper>

      <TypeOfMaterialPrimarySelectField
        triggerSaveMessageHandler={triggerSaveMessageHandler}
        materialSettings={materialSettings}
        doiField
      />

      <InputWrapper
        title="Material Title or Name"
        infoBoxContent={infoBoxesContent.materialTitleInfo}
        required
      >
        <div
          className="markdownText"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: materialTitleText,
          }}
        />

        <InputField
          name="title"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />
      </InputWrapper>

      <InputWrapper title="General Research Area" infoBoxContent="" required>
        <HierarchicalSelectField
          options={researchAreas}
          inputName="researchArea"
          irisMaterialSection="instrument"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
        />

        <NewOtherForm
          title="research area"
          inputName="researchAreaNew"
          fieldName="researchArea"
          dictionaryStateName="researchAreas"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          showParent
          options={researchAreas}
        />
      </InputWrapper>

      <InputWrapper title="Type of File" infoBoxContent="" required>
        <SelectField
          name="typeOfFile"
          options={fileTypes}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          required
        />
      </InputWrapper>

      <InputWrapper
        title="Data Type Elicited"
        infoBoxContent={infoBoxesContent.materialDataTypeInfo}
      >
        <SelectField
          name="dataType"
          options={dataTypes}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Linguistic Feature"
        infoBoxContent={infoBoxesContent.materialLinguisticFeatureInfo}
      >
        <SelectField
          name="linguisticFeature"
          options={linguisticFeatures}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Language of Instructions Within Material"
        infoBoxContent={
          infoBoxesContent.materialLanguageOfInstructionsWithinMaterialInfo
        }
      >
        <SelectField
          name="language"
          options={languages}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Source of Funding"
        infoBoxContent={infoBoxesContent.materialSourceOfFunding}
      >
        <SelectField
          name="sourceOfFunding"
          options={funders}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Reliability Info"
        infoBoxContent={infoBoxesContent.materialReliabilityInfo}
      >
        <InputField
          name="reliability"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Notes"
        infoBoxContent={infoBoxesContent.materialNotesInfo}
      >
        <TextareaField
          name="notes"
          height={100}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}
