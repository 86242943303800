/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default function Step0() {
  const query = useStaticQuery(graphql`
    query {
      submissionIntroductionMessageQuery: allMarkdownRemark(
        filter: {
          frontmatter: { title: { glob: "Submission Introduction Message" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionIntroductionMessage =
    query.submissionIntroductionMessageQuery.edges[0].node.html;

  return (
    <div
      className="markdownText"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: submissionIntroductionMessage,
      }}
    />
  );
}
