import React from "react";

interface StepProps {
  materialID: string;
}

export default function Step9(props: StepProps) {
  const { materialID } = props;

  return (
    <div className="pb-5 mb-5 border-b border-b-gray-2">
      <p className="">
        Thank you for submitting the material.
        <br />
        <br />
        You can see the new material <a href={`/details/${materialID}`}>here</a>
        .
      </p>
    </div>
  );
}
