/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import clsx from "clsx";
import { isNumber, isArray } from "lodash";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
} from "../../../types/UIElements";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface RadioFieldProps {
  name: string;
  options: string[];
  additionalClasses?: string;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
}

export default function RadioField(props: RadioFieldProps) {
  const submissionState = useContext(SubmissionState);
  const submissionDispatch = useContext(SubmissionDispatch);

  const {
    name,
    options = [],
    additionalClasses,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
  } = props;

  let fieldValue = [""];

  if (isNumber(referenceNumber)) {
    fieldValue = submissionState?.publication[referenceNumber][name]
      ? submissionState.publication[referenceNumber][name]
      : "";
  } else if (isNumber(acknowledgementNumber)) {
    fieldValue = submissionState?.acknowledgement[acknowledgementNumber][name]
      ? submissionState.acknowledgement[acknowledgementNumber][name]
      : "";
  } else if (participantsPropertyList.includes(name)) {
    fieldValue = submissionState?.participant[name]
      ? submissionState.participant[name]
      : "";
  } else if (instrumentPropertyList.includes(name)) {
    fieldValue = submissionState?.instrument[name]
      ? submissionState.instrument[name]
      : "";
  } else if (settingsPropertyList.includes(name)) {
    fieldValue = submissionStat?.settings[name]
      ? submissionState?.settings[name]
      : "";
  } else {
    fieldValue = submissionState[name] ? submissionState[name] : "";
  }

  // Detect which handler to use
  let onChangeHandler = "inputChangeHandler";
  if (isNumber(referenceNumber)) {
    onChangeHandler = "publicationChangeHandler";
  } else if (isNumber(acknowledgementNumber)) {
    onChangeHandler = "acknowledgementChangeHandler";
  } else if (participantsPropertyList.includes(name)) {
    onChangeHandler = "participantChangeHandler";
  } else if (instrumentPropertyList.includes(name)) {
    onChangeHandler = "instrumentChangeHandler";
  } else if (settingsPropertyList.includes(name)) {
    onChangeHandler = "settingsChangeHandler";
  } else {
    //
  }

  let checkedValue = "";
  if (isArray(fieldValue)) {
    [checkedValue] = fieldValue;
  } else {
    checkedValue = fieldValue;
  }

  return (
    <>
      {options.map((v: any, i: any) => {
        const key = `${name}${i}`;

        const additionalMarginClass = i > 0 ? "ml-2" : "";

        return (
          <span key={key}>
            <input
              type="radio"
              id={key}
              name={name}
              checked={checkedValue === v}
              className={clsx(additionalClasses, additionalMarginClass)}
              onChange={(e) => {
                // Cancel current saveTimer when new changes are coming in before timeout passes
                clearTimeout(saveTimer);

                // Update state
                submissionDispatch({
                  type: onChangeHandler,
                  value: e.target.value,
                  fieldName: name,
                });

                // Trigger save after X milliseconds to avoid too many save requests
                // timer is canceled on new change event
                saveTimer = setTimeout(() => {
                  triggerSaveMessageHandler();
                }, 500);
              }}
              value={v}
            />
            {` ${v}`}
          </span>
        );
      })}
    </>
  );
}
